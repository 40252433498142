// File: frontend/main/src/hooks/useScrollRestoration.js
import { useEffect } from 'react';

function useScrollRestoration() {
  useEffect(() => {
    if ('scrollRestoration' in window.history) {
      window.history.scrollRestoration = 'manual';
    }
    window.scrollTo(0, 0);
  }, []);

  return null;
}

export default useScrollRestoration;
