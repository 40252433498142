// File: frontend/main/src/pages/main/index.js
import React from 'react';
import useScrollRestoration from '../../hooks/useScrollRestoration';
import styles from './style.module.css';
// import MainTitle from '../../components/MainTitle';

const Header = React.lazy(() => import('../../components/Header/index'));
const Footer = React.lazy(() => import('../../components/Footer'));
const Unique = React.lazy(() => import('../../components/Unique'));
const InfoBlock = React.lazy(() => import('../../components/Info'));
const Mint = React.lazy(() => import('../../components/Mint'));
const SpotlightBanner = React.lazy(
  () => import('../../components/SpotlightBanner')
);

const Survival = React.lazy(() => import('../../components/Survival'));

function HomePage() {
  useScrollRestoration();

  return (
    <div className={styles.App}>
      <Header />
      <SpotlightBanner />
      {/* <MainTitle text='Ape Society' /> */}
      <InfoBlock />
      <Survival />
      <Unique />
      <Mint />
      <Footer />
    </div>
  );
}

export default React.memo(HomePage);
