// File: frontend/main/src/contexts/themeContext.js
import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useMemo,
  useCallback,
} from 'react';
import PropTypes from 'prop-types';

const ThemeContext = createContext();

export function ThemeProvider({ children }) {
  const [theme, setTheme] = useState(
    window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches
      ? 'dark'
      : 'light'
  );

  const updateCSSVariables = useCallback((newTheme) => {
    const root = document.documentElement;
    if (newTheme === 'light') {
      root.style.setProperty('--text-color', '#000000');
      root.style.setProperty(
        '--background-gradient',
        'var(--background-light)'
      );
    } else {
      root.style.setProperty('--text-color', '#ffffff');
      root.style.setProperty('--background-gradient', 'var(--background-dark)');
    }
  }, []);

  useEffect(() => {
    updateCSSVariables(theme);

    const themeMediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const handleChange = (e) => {
      setTheme(e.matches ? 'dark' : 'light');
    };

    themeMediaQuery.addEventListener('change', handleChange);

    return () => {
      themeMediaQuery.removeEventListener('change', handleChange);
    };
  }, [theme, updateCSSVariables]);

  const contextValue = useMemo(() => ({ theme, setTheme }), [theme]);

  return (
    <ThemeContext.Provider value={contextValue}>
      {children}
    </ThemeContext.Provider>
  );
}

ThemeProvider.propTypes = {
  children: PropTypes.node.isRequired,
};

export const useTheme = () => useContext(ThemeContext);
