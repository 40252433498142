// File: frontend/main/src/App.js
import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { ThemeProvider } from './contexts/themeContext';
import HomePage from './pages/main';
import './index.css';

function App() {
  return (
    <ThemeProvider>
      <Routes>
        <Route path='/' element={<HomePage />} />
      </Routes>
    </ThemeProvider>
  );
}

export default App;
